import { Component, ChangeDetectionStrategy, Input, OnChanges } from '@angular/core';
import { CartService } from '@app/shared/services/cart.service';
import { CartItem } from '@app/shared/models';

@Component({
	selector: 'cart-list-item',
	templateUrl: 'cart-list-item.component.html',
	styleUrls: ['cart-list-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartListItemComponent {
	@Input() public readonly cartItem: CartItem;

	constructor(
		private _cart: CartService,
	) {}

	public addOne() {
		this._cart.addOne(this.cartItem.design.id);
	}

	public removeOne() {
		if (this.cartItem.amount === 1 && !confirm('Biztos, hogy teljesen el akarod távolítani ezt a tervet a kosaradból?')) return;
		this._cart.removeOne(this.cartItem.design.id);
	}

	public removeAll() {
		if (!confirm('Biztos, hogy teljesen el akarod távolítani ezt a tervet a kosaradból?')) return;
		this._cart.removeMany(this.cartItem.design.id, this.cartItem.amount);
	}

	// ngOnChanges(changes: SimpleChanges) {
	// 	for (const propName in changes) {
	// 		const chng = changes[propName];
	// 		// console.log(`${propName}: currentValue = `, chng.currentValue, `previousValue = `, chng.previousValue);
	// 		try {
	// 			console.log(chng.currentValue, chng.previousValue, chng.currentValue.design === chng.previousValue.design);
	// 		} catch (e) {
	// 			//
	// 		}
	// 	}
	// }
}
