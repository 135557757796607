import { NgModule, Inject, PLATFORM_ID, APP_ID, LOCALE_ID } from '@angular/core';
import { isPlatformBrowser, registerLocaleData } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TransferHttpCacheModule } from '@nguniversal/common';

import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import localeHu from '@angular/common/locales/hu';

import { AppRoutingModule } from './app-routing.module';

import { CoreModule } from '@app-core/core.module';
import { SharedModule } from '@app-shared/shared.module';

import { AppComponent } from './app.component';


registerLocaleData(localeHu, 'hu');

@NgModule({
	declarations: [
		AppComponent,
	],
	imports: [
		BrowserModule.withServerTransition({ appId: 'website' }),
		TransferHttpCacheModule,
		BrowserAnimationsModule,

		HttpClientModule,
		HttpClientJsonpModule,

		AppRoutingModule,
		CoreModule,
		SharedModule,
	],
	providers: [ { provide: LOCALE_ID, useValue: 'hu' } ],
})
export class AppModule {

	constructor(
		@Inject(PLATFORM_ID) private platformId: object,
		@Inject(APP_ID) private appId: string
	) {
		const platform = isPlatformBrowser(platformId) ? 'in the browser' : 'on the server';
		console.log(`Running ${platform} with appId=${appId}`);
	}
}
