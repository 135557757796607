import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';

@Injectable({
	providedIn: 'root',
})
export class ErrorService {
	protected translations: any;

	protected errors = {
		'unknown_error': 'Ismeretlen hiba!',
		'app:error': 'Ismeretlen hiba történt! Kérjük próbáld újra!',
		// REGISTER
		'email:missing': 'Hiányzó e-mail cím',
		'email:required': 'Hiányzó e-mail cím',
		'email_already_registered': 'Ezzel az e-mail címmel már van regisztráció!',
		'email:already_exists': 'Ezzel az e-mail címmel már van regisztráció!',
		'validation.already_exists': 'Ezzel az e-mail címmel már van regisztráció!',
		'email:invalid': 'Hibás e-mail formátum!',
		'sex:missing': 'Hiányzó nem!',
		'city:missing': 'Hiányzó város!',
		'street_name:missing': 'Hiányzó utca név!',
		'street_type:missing': 'Hiányzó közterület!',
		'house_number:missing': 'Hiányzó házszám!',
		'postal_code:missing': 'Hiányzó irányítószám!',
		'postal_code:invalid': 'Hibás irányítószám!',
		'phone_area:missing': 'Hiányzó előhívószám!',
		'phone_area:invalid': 'Hibás előhívószám!',
		'phone_subscriber:missing': 'Hiányzó telefonszám!',
		'phone_subscriber:invalid': 'Hibás telefonszám!',
		'date_of_birth:invalid': 'Hibás születési idő!',
		'date_of_birth:too_young': 'A játékban csak 18 éven felüliek vehetnek részt! Ha elmúlt már 18 éves ellenőrizze a megadott születési dátumot!',
		'over_18:invalid': 'A játékban csak 18 éven felüliek vehetnek részt!',
		'date_of_birth:too_old': 'Kérjük, adja meg a valós születési éved!',
		'accept_terms:missing': 'Nem fogadtad el a játékszabályzatot!',
		'size:invalid': 'Nem választottál ruhaméretet!',
		'last_name:missing': 'Hiányzó vezetéknév!',
		'last_name:invalid': 'A megadott vezetéknév nem tűnik helyesnek!',
		'first_name:missing': 'Hiányzó keresztnév!',
		'first_name:invalid': 'A megadott keresztnév nem tűnik helyesnek!',
		'invited_by:invalid': 'Kérlek add meg, honnan értesültél a promócióról!',
		// UPLOAD
		'code:invalid': 'Ellenőrizd az AP kódot!',
		'code:not_found': 'Ellenőrizd az AP kódot!',
		'code:already_uploaded': 'A kód már fel lett töltve! Add meg a blokkon található nyugtaszámot is!',
		'code:missing': 'Hiányzó AP kód!',
		'code:required': 'Hiányzó AP kód!',
		'code:invalid_length': 'Nem 6 karakter hosszú a kód!',
		'code:extra_unavailable': 'A feltöltött extra kódok száma nem haladhatja meg a feltöltött csomagoláskódok számát. Tölts fel egy csomagoláskódot, hogy extra kódoddal is részt vehess a játékban.',
		'code:blacklisted': 'Ez a kódot nem töltheti fel!',
		'code:unauthorized': 'Az extra kódhoz nem a megadott email cím tartozik!',
		'receipt_number:already_uploaded': 'Ez a kód és nyugtaszám már fel lett töltve!',
		'receipt_number:invalid': 'A nyugtaszám nem tűnik megfelelő formátumúnak.',
		'extra:unavailable': 'Sajnáljuk, az extra kód igénylése nem sikerült! Kérünk, ellenőrizd, hogy elegendő csomagoláskódot töltöttél-e fel, illetve, hogy felhasználtad-e már a korábban megnyert extra kódot.',
		'purchase_time:invalid': 'Érvénytelen vásárlási dátum!',
		'purchase_time:required': 'Hiányzó vásárlási dátum!',
		'purchase_time:too_late': 'A vásárlás időpontja nem lehet jövőbeni!',
		'prize:already_won': 'Ezt a nyereményt már egyszer megnyerte!',
		'user_not_found': 'Ez a email cím nem található!',
		'email_not_found': 'Ezzel az email címmel nincs regisztráció!',
		'user:not_found': 'Ez a email cím nem található!',
		'email:not_found': 'Ezzel az email címmel nincs regisztráció!',
		'promotion:outdated': 'A promóció augusztus 1-én indul.',
		'token:invalid': 'Érvénytelen megerősítő kód.',
		'token:not_found': 'Érvénytelen megerősítő kód.',
		'token:already_confirmed': 'Érvénytelen megerősítő kód.',
		'recaptcha:invalid': 'Add meg kérlek a reCaptcha-t!',
	};

	public get(errorCode: string): string {
		if (typeof this.errors[errorCode] !== 'undefined') {
			return this.errors[errorCode];
		} else {
			console.error(`error message not found: ${errorCode}`);
			Sentry.withScope(scope => {
				scope.setTag('module', 'ErrorService');
				scope.setExtra('error', errorCode);
				Sentry.captureMessage('Error message not found');
			});
			return this.errors.unknown_error;
		}
	}

	public getFieldName(errorMsg: string): string {
		const fields = errorMsg.split(':');
		let field;

		if (fields.length) {
			field = fields[0];
			if (field === 'date_of_birth' || field === 'over18') {
				field = 'year';
			}
			return field;
		}

		return '';
	}

	public getErrorCode(errorCode: string): string {
		const fields = errorCode.split(':');
		let code;

		if (fields.length) {
			code = fields[1];
			return code;
		}

		return '';
	}

	/**
	 * Get all the error codes and messages for a field
	 *
	 * @param field string
	 * @returns {Object[]}
	 */
	public getErrorMessagesForField(field: string): object[] {
		const fieldErrorMessages: object[] = [];
		for (const errorCode in this.errors) {
			if (this.getFieldName(errorCode) === field) {
				fieldErrorMessages.push({
					errorCode: this.getErrorCode(errorCode),
					errorMessage: this.errors[errorCode],
				});
			}
		}
		return fieldErrorMessages;
	}

	protected parseTranslation() {
		for (const translation in this.translations) {
			if (translation.includes('error')) {
				console.log(translation);
			}
		}
	}
}
