import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap, pluck } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class UserService {
	public readonly user$ = new BehaviorSubject(null);

	public readonly passwordResetToken$ = new BehaviorSubject<string>(null);

	constructor(
		private _http: HttpClient,
	) {
		this.getLoggedInUser().subscribe();
	}

	public getLoggedInUser(): Observable<any> {
		return this._http.get(
			'/legacy-api/api-js/json/getLoggedInUser',
		).pipe(
			pluck('result', 'user'),
			tap(user => {
				console.log('getLoggedInUser tap', user);
				if (user) this.user$.next(user);
			})
		);
	}

	public login(data: object): Observable<any> {
		return this._http.post(
			'/legacy-api/api-js/json/login',
			{
				auth_type: 'password',
				...data,
			}
		).pipe(
			pluck('result', 'user'),
			tap(user => {
				console.log('login tap', user);
				if (user) this.user$.next(user);
			})
		);
	}

	public logout() {
		this._http.post<any>(
			'/legacy-api/api-js/json/logout',
			{}
		).subscribe(() => {
			this.user$.next(null);
		});
	}

	public register(data: object): Observable<any> {
		return this._http.post(
			'/legacy-api/api-js/json/register',
			{
				auth_type: 'password',
				...data,
			}
		).pipe(
			pluck('result', 'user'),
			tap(user => {
				console.log('register tap', user);
			}),
		);
	}

	public createGuestUser(): Observable<any> {
		return this._http.post(
			'/legacy-api/api-js/json/register',
			{
				auth_type: 'guest'
			}
		).pipe(
			pluck('result', 'user'),
			tap(user => {
				console.log('createGuestUser tap', user);
				if (user) this.user$.next(user);
			}),
		);
	}

	public resetPassword(data: object): Observable<any> {
		return this._http.post(
			'/legacy-api/api-js/json/resetPassword',
			data
		).pipe(
			pluck('result', 'user'),
			tap(user => {
				console.log('register tap', user);
			}),
		);
	}

	public sendPasswordResetToken(data: object): Observable<any> {
		return this._http.post(
			'/legacy-api/api-js/json/sendPasswordResetToken',
			data
		).pipe(
			pluck('result', 'user'),
			tap(user => {
				console.log('register tap', user);
			}),
		);
	}

	public getUserByPasswordResetToken(token: string): Observable<any> {
		return this._http.post(
			'/legacy-api/api-js/json/getUserByPasswordResetToken',
			{
				token,
			}
		).pipe(
			pluck('result', 'user'),
			tap(user => {
				console.log('register tap', user);
			}),
		);
	}
}
