import { ErrorHandler, Inject } from '@angular/core';
import { environment } from '@app-env/environment';

import * as Sentry from '@sentry/minimal';

export class SentryErrorHandler implements ErrorHandler {

	constructor(@Inject('Sentry') private _sentry: any) {
		console.log('sentry init', _sentry.SDK_NAME, _sentry.SDK_VERSION);

		_sentry.init({
			dsn:			environment.SERVICE_SENTRY_DSN_PUBLIC,
			environment:	environment.APP_ENV,
			release:		environment.COMMIT_HASH.substr(0, 8),
			blacklistUrls:	[
				/^(https:\/\/www\.gstatic\.com)?\/recaptcha\/releases\/.*/,
			],
			ignoreErrors: [
				/^reCAPTCHA client has been deleted: \d+$/,
				/^ReportingObserver \[(deprecation|intervention)\]/,
			],
		});

		Sentry.configureScope((scope) => {
			scope.setTag('logger', 'javascript');
		});

		// Sentry.withScope(scope => {
		// 	scope.setTag('module', 'test');
		// 	scope.setExtra('sentry_sdk', _sentry.SDK_NAME);
		// 	Sentry.captureMessage('Sentry test');
		// });
	}

	handleError(err: any): void {
		Sentry.captureException(err.originalError || err);
	}
}
