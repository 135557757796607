/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./footer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./footer.component";
var styles_FooterComponent = [i0.styles];
var RenderType_FooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
export function View_FooterComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "col-md-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "p", [["class", "text-md-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "a", [["href", "http://www.rossmann.hu/"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A9 Rossmann Magyarorsz\u00E1g Kft. - www.rossmann.hu"])), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "col-md-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "i", [["class", "icomoon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u25C9"])), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [["class", "col-md-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "p", [["class", "text-md-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "a", [["href", "/assets/pdf/szabalyzat_aszf_jogi_es_adatvedelmi_nyilatkozat.pdf"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Szab\u00E1lyzat, \u00C1SZF, Jogi \u00E9s Adatv\u00E9delmi Nyilatkozat"]))], null, null); }
export function View_FooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "footer", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i1.ɵdid(1, 49152, null, 0, i2.FooterComponent, [], null, null)], null, null); }
var FooterComponentNgFactory = i1.ɵccf("footer", i2.FooterComponent, View_FooterComponent_Host_0, {}, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };
