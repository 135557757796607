<div class="container">
	<div class="row">
		<div class="col-md-4">
			<p class="text-md-left">
				<a href="http://www.rossmann.hu/" target="_blank">&copy; Rossmann Magyarország Kft. - www.rossmann.hu</a>
			</p>
		</div>
		<div class="col-md-4">
			<p><i class="icomoon">&#x25c9;</i></p>
		</div>
		<div class="col-md-4">
			<p class="text-md-right">
				<a href="/assets/pdf/szabalyzat_aszf_jogi_es_adatvedelmi_nyilatkozat.pdf" target="_blank">Szabályzat, ÁSZF, Jogi és Adatvédelmi Nyilatkozat</a>
			</p>
		</div>
	</div>
</div>
