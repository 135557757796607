<div class="modal-header">
	<h2 [ngClass]="type" class="modal-title">{{ title }}</h2>
	<button *ngIf="dismissBtn" type="button" class="btn btn-link" (click)="activeModal.dismiss()">
		<fa-icon [icon]="['far', 'times']" size="lg" [styles]="{ 'color': 'white' }"></fa-icon>
	</button>
</div>
<div class="modal-body" [innerHTML]="message"></div>
<div class="modal-footer">
	<ng-container *ngIf="buttons; else defaultButtons">
		<ng-container *ngFor="let button of buttons">
			<ng-container [ngSwitch]="button.type">
				<button *ngSwitchCase="'dismiss'" type="button" [ngClass]="button?.class" (click)="activeModal.dismiss(button.id)">{{ button.caption }}</button>
				<button *ngSwitchCase="'eventemitter'" type="button" [ngClass]="button?.class" (click)="buttonClicked(button.id)">{{ button.caption }}</button>
				<button *ngSwitchDefault type="button" [ngClass]="button?.class" (click)="activeModal.close(button.id)">{{ button.caption }}</button>
			</ng-container>
		</ng-container>
	</ng-container>

	<ng-template #defaultButtons>
		<button type="button" class="btn btn-red" (click)="activeModal.close()">OK</button>
	</ng-template>
</div>
