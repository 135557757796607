import { Component, ChangeDetectionStrategy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginRegistrationModalComponent, CartModalComponent } from '@app/shared/modals';
import { UserService } from '@app/shared/services/user.service';
import { CartService } from '@app/shared/services/cart.service';
import { ToastService } from '@app/shared/services/toast.service';
import { map, tap, filter, first, switchMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
	selector: 'header[top]',
	templateUrl: 'header.component.html',
	styleUrls: ['header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
	public isNavbarCollapsed: boolean = true;

	public readonly user$ = this._user.user$;
	public readonly cartItemCount$ = this._cart.cartItemCount$;

	constructor(
		router: Router,
		public toastService: ToastService,
		private _modal: NgbModal,
		private _user: UserService,
		private _cart: CartService,
	) {
		router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.isNavbarCollapsed = true;
			}
		});

		// this.toastService.show('hiba', 'I am a standard toast', { delay: '500000' });

		_user.passwordResetToken$.pipe(
			filter(passwordResetToken => !!passwordResetToken),
			first(),
			switchMap(token => _user.getUserByPasswordResetToken(token), (token, user) => [token, user]),
		).subscribe(
			([token, user]) => {
				console.log(token, user);
				const modal = this._modal.open(LoginRegistrationModalComponent, { size: 'lg' });
				modal.componentInstance.activeForm = 'password-reset';
				modal.componentInstance.passwordResetForm.patchValue({ token });
				},
			(error: HttpErrorResponse) => {
				console.error(error.error);
			}
		);
	}

	public showLoginRegisterModal() {
		this._modal.open(LoginRegistrationModalComponent, { size: 'lg' });
	}

	public showCartModal() {
		this._modal.open(CartModalComponent, { size: 'lg' });
	}

	public logout() {
		const isGuest = this._user.user$.getValue().auth_type === 'guest';
		if (isGuest && !confirm('Vendégként használod az oldalt. Ha kilépsz, a tervezett címkéid és a kosarad tartalma elvész!')) return;
		this._user.logout();
	}

}
