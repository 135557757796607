import * as i0 from "@angular/core";
export class ToastService {
    constructor() {
        this.toasts = [];
    }
    // show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    // 	console.log('toast');
    // 	this.toasts.push({ textOrTpl, ...options });
    // }
    show(header, body, options = {}) {
        this.toasts.push(Object.assign({ header, body }, options));
    }
    remove(toast) {
        this.toasts = this.toasts.filter(t => t !== toast);
    }
}
ToastService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ToastService_Factory() { return new ToastService(); }, token: ToastService, providedIn: "root" });
