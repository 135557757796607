<nav class="navbar navbar-expand-lg fixed-top navbar-rossmann">
	<div class="container">
		<a class="navbar-brand" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" data-icon="&#x25c9;"></a>
		<button class="navbar-toggler hamburger hamburger--squeeze" type="button" (click)="isNavbarCollapsed = !isNavbarCollapsed" aria-controls="navbarContent" [attr.aria-expanded]="!isNavbarCollapsed" aria-label="Toggle navigation">
			<span class="hamburger-box">
				<span class="hamburger-inner"></span>
			</span>
		</button>
		<div [ngbCollapse]="isNavbarCollapsed" class="collapse navbar-collapse" id="navbarContent">
			<ul class="navbar-nav mr-auto">
				<li class="nav-item">
					<a routerLink="/tervezo" routerLinkActive="active" class="nav-link">
						Tervező
					</a>
				</li>
				<li class="nav-item">
					<a routerLink="/terveim" routerLinkActive="active" class="nav-link">
						Terveim
					</a>
				</li>
				<li class="nav-item">
					<a routerLink="/galeria" routerLinkActive="active" class="nav-link">
						Galéria
					</a>
				</li>
				<li class="nav-item">
					<a routerLink="/infok" routerLinkActive="active" class="nav-link">
						Infók
					</a>
				</li>
			</ul>
			<div class="buttons">
				<ng-container *ngIf="user$ | async as user; else noSession">
					<button *ngIf="user.auth_type !== 'guest'" type="button" class="btn btn-nav">Üdv, {{ user.firstname }}!</button>
					<button *ngIf="user.auth_type === 'guest'" type="button" class="btn btn-nav">Vendég felhasználó</button>
					<button type="button" class="btn btn-nav" (click)="showCartModal()">
						<fa-icon [icon]="['far', 'shopping-cart']" [fixedWidth]="true" size="lg" [styles]="{ 'color': 'white' }"></fa-icon> <ng-container *ngIf="cartItemCount$ | async as count"> {{ count }} termék</ng-container>
					</button>
					<button type="button" class="btn btn-nav" (click)="logout()">
						<fa-icon [icon]="['far', 'sign-out-alt']" [fixedWidth]="true" size="lg" [styles]="{ 'color': 'white' }"></fa-icon>
					</button>
				</ng-container>
				<ng-template #noSession>
					<button type="button" class="btn btn-nav" (click)="showLoginRegisterModal()">Belépés/Regisztráció</button>
				</ng-template>
			</div>
		</div>
	</div>
</nav>
