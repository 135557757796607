export const environment = {
	production: true,
	hmr: false,

	APP_ENV: 'local',
	COMMIT_HASH: '5f8bae09fea861deb8726e418d368eb49b173ffe',
	API_URL: '/api/',
	SERVICE_SENTRY_DSN_PUBLIC: 'https://3ce106a1c3924a9db5f2c1a3dd804fa8:296f663f6d984fbf9efae6273852846e@sentry.io/2265797',
	GOOGLE_CLOUD_STORAGE_BUCKET: 'rossmanndesign-beta',
};
