import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Cart, CartItem } from '@app/shared/models';

@Component({
	selector: 'cart-list',
	templateUrl: 'cart-list.component.html',
	styleUrls: ['cart-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartListComponent {
	@Input() cart: Cart;

	trackByDesignId = (index: number, item: CartItem) => item.design.id;
}
