import { MessageModalComponent } from './message/message.component';
import { LoginRegistrationModalComponent } from './login-registration/login-registration.component';
import { CartModalComponent } from './cart/cart.component';

export const SHARED_MODAL_COMPONENTS = [
	MessageModalComponent,
	LoginRegistrationModalComponent,
	CartModalComponent,
];

export * from './message/message.component';
export * from './login-registration/login-registration.component';
export * from './cart/cart.component';
