/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cart-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../cart-list-item/cart-list-item.component.ngfactory";
import * as i3 from "../cart-list-item/cart-list-item.component";
import * as i4 from "../../services/cart.service";
import * as i5 from "@angular/common";
import * as i6 from "./cart-list.component";
var styles_CartListComponent = [i0.styles];
var RenderType_CartListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CartListComponent, data: {} });
export { RenderType_CartListComponent as RenderType_CartListComponent };
function View_CartListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cart-list-item", [], null, null, null, i2.View_CartListItemComponent_0, i2.RenderType_CartListItemComponent)), i1.ɵdid(1, 49152, null, 0, i3.CartListItemComponent, [i4.CartService], { cartItem: [0, "cartItem"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CartListComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CartListComponent_1)), i1.ɵdid(1, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cart; var currVal_1 = _co.trackByDesignId; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_CartListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cart-list", [], null, null, null, View_CartListComponent_0, RenderType_CartListComponent)), i1.ɵdid(1, 49152, null, 0, i6.CartListComponent, [], null, null)], null, null); }
var CartListComponentNgFactory = i1.ɵccf("cart-list", i6.CartListComponent, View_CartListComponent_Host_0, { cart: "cart" }, {}, []);
export { CartListComponentNgFactory as CartListComponentNgFactory };
