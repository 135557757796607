import { Component, TemplateRef, HostBinding } from '@angular/core';

import { ToastService } from '@app/shared/services/toast.service';

@Component({
	selector: 'app-toasts',
	templateUrl: 'toasts.component.html',
	styleUrls: [ 'toasts.component.scss' ]
})
export class ToastsContainerComponent {
	@HostBinding('class.ngb-toasts') class = true;

	constructor(
		public toastService: ToastService
	) {}

	isTemplate(toast) { return toast.textOrTpl instanceof TemplateRef; }
}
