import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserTransferStateModule } from '@angular/platform-browser';

import { SentryErrorHandler } from './core/services/sentry-error-handler';
import * as SentryBrowser from '@sentry/browser';

import { AppModule } from './app.module';
import { AppComponent } from './app.component';
import { environment } from '@app-env/environment';


const SENTRY_PROVIDERS = environment.production ? [
	{ provide: 'Sentry', useValue: SentryBrowser },
	{ provide: ErrorHandler, useClass: SentryErrorHandler }
] : [];

@NgModule({
	imports: [
		AppModule,

		BrowserTransferStateModule,
	],
	providers: [
		...SENTRY_PROVIDERS
	],
	bootstrap: [ AppComponent ],
})
export class AppBrowserModule {}
