import * as tslib_1 from "tslib";
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { hmrBootstrap } from './hmr';
import * as __NgCli_bootstrap_1 from "./app/app.browser.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
// import { create } from 'rxjs-spy';
// if (!environment.production) {
// 	const spy = create();
// 	spy.log();
// }
if (environment.production) {
    enableProdMode();
    [
        'debug', 'log', 'info', 'warn', 'error',
        'group', 'groupCollapsed', 'groupEnd',
        'dir', 'dirxml', 'trace', 'profile'
    ].forEach(method => window.console[method] = () => void 0);
}
const bootstrap = () => tslib_1.__awaiter(this, void 0, void 0, function* () {
    return __NgCli_bootstrap_2.platformBrowser()
        .bootstrapModuleFactory(__NgCli_bootstrap_1.AppBrowserModuleNgFactory)
        .catch((err) => console.log(err));
});
const ɵ0 = bootstrap;
if (environment.hmr) {
    if (module.hot) {
        hmrBootstrap(module, bootstrap);
    }
    else {
        console.error('HMR is not enabled!');
    }
}
else {
    document.addEventListener('DOMContentLoaded', () => {
        console.log('DOMContentLoaded');
        bootstrap();
    });
}
export { ɵ0 };
