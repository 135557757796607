import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Cart, CartItem } from '@app/shared/models';

// actions

export const load = createAction('[Cart] load');
export const loadSuccess = createAction('[Cart] load success', props<{ cart: Cart }>());
export const add = createAction('[Cart] add one', props<{ design_id: number, amount?: number }>());
export const addSuccess = createAction('[Cart] add one success', props<{ cartItem: CartItem }>());
export const remove = createAction('[Cart] remove all', props<{ design_id: number, amount?: number }>());
export const updateSuccess = createAction('[Cart] update success', props<{ cartItemUpdate: Update<CartItem> }>());


