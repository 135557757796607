import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { CartItem } from '@app/shared/models';

// state

export const subFeatureKey = 'cart';

export interface State extends EntityState<CartItem> {}

export function selectCartItemId(item: CartItem): number {
	return item.design.id;
}
export const adapter: EntityAdapter<CartItem> = createEntityAdapter<CartItem>({
	selectId: selectCartItemId
});

export const initialState: State = adapter.getInitialState({});


