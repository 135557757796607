import { Routes } from '@angular/router';
const ɵ0 = () => import("./home/home.module.ngfactory").then(m => m.HomeModuleNgFactory);
const appRoutes = [
    {
        path: '',
        loadChildren: ɵ0
    },
];
export class AppRoutingModule {
}
export { ɵ0 };
