<ng-container [ngSwitch]="activeForm">
	<ng-container *ngSwitchCase="'cart'">
		<div class="modal-header">
			<h2 class="modal-title">Kosár tartalma</h2>
			<button type="button" class="btn btn-link" (click)="activeModal.dismiss()">
				<fa-icon [icon]="['far', 'times']" size="lg" [styles]="{ 'color': 'white' }"></fa-icon>
			</button>
		</div>
		<div class="modal-body">
			<ng-container *ngIf="cart$ | async as cart">
				<ng-container *ngIf="cart?.length > 0; else emptyCart">
					<cart-list [cart]="cart"></cart-list>
					<p class="text-right">
						Összesen: <strong>{{ cartPriceTotal$ | async }} Ft</strong>
					</p>
				</ng-container>
				<ng-template #emptyCart>
					<p class="text-center">Üres a kosár.</p>
				</ng-template>
			</ng-container>
		</div>
		<div class="modal-footer">
			<button type="button" (click)="activeForm = 'order'" class="btn btn-square-green" [disabled]="(cart$ | async)?.length === 0">Megrendelem</button>
		</div>
	</ng-container>
	<ng-container *ngSwitchCase="'order'">
		<div class="modal-header">
			<h2 class="modal-title">Megrendelés</h2>
			<button type="button" class="btn btn-link" (click)="activeModal.dismiss()">
				<fa-icon [icon]="['far', 'times']" size="lg" [styles]="{ 'color': 'white' }"></fa-icon>
			</button>
		</div>
		<form [formGroup]="orderForm" (ngSubmit)="submitOrder()" id="form-order" novalidate>
			<div class="modal-body">
				<p><strong>Kérjük, add meg a megrendeléshez szükséges adataidat, illetve válaszd ki, azt a Rossmann üzletet, ahol át szeretnéd venni a terméket.</strong> A termék árát az átvételkor, a választott üzletben tudod majd kiegyenlíteni. A szállítási idő előreláthatólag 5-9 nap. Az átvétel várható időpontjáról emailben értesítést küldünk.</p>
				<div class="row">
					<div class="col-md-6">
						<div class="form-group">
							<label for="order_lastname">Vezetéknév</label>
							<input id="order_lastname" type="text" name="order_lastname" formControlName="lastname" class="form-control" required>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group">
							<label for="order_firstname">Keresztnév</label>
							<input id="order_firstname" type="text" name="order_firstname" formControlName="firstname" class="form-control" required>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-2">
						<div class="form-group">
							<label for="order_postal_code">Ir.szám</label>
							<input id="order_postal_code" type="text" name="order_postal_code" formControlName="postal_code" [attr.maxlength]="4" class="form-control" required>
						</div>
					</div>
					<div class="col-md-4">
						<div class="form-group">
							<label for="order_city">Település</label>
							<input id="order_city" type="text" name="city" formControlName="city" class="form-control" required>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group">
							<label for="order_street_address">Utca, házszám</label>
							<input id="order_street_address" type="text" name="order_street_address" formControlName="street_address" class="form-control" required>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6">
						<div class="form-group">
							<label for="order_email">E-mail cím</label>
							<input id="order_email" type="email" name="order_email" formControlName="email" class="form-control" required>
						</div>
					</div>
					<div class="col-md-6">
						<div class="row">
							<div class="col-md-4">
								<div class="form-group">
									<label for="order_phone_area">Előhívó</label>
									<input id="order_phone_area" type="text" name="order_phone_area" formControlName="phone_area" [attr.maxlength]="2"  class="form-control" required>
								</div>
							</div>
							<div class="col-md-8">
								<div class="form-group">
									<label for="order_phone_subscriber">Telefonszám</label>
									<input id="order_phone_subscriber" type="text" name="order_phone_subscriber" formControlName="phone_subscriber" [attr.maxlength]="7" class="form-control" required>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="form-group">
					<label for="order_store">Rossmann üzlet választása</label>
					<ng-select id="order_store" name="order_store" class="select-rossmann"
						[items]="stores$ | async"
						placeholder="Válassz!"
						bindValue="value"
						bindLabel="label"
						formControlName="store_id"
						[searchable]="true"
						[clearable]="false">
					</ng-select>
				</div>
				<div *ngIf="errorMessages.length" class="alert alert-danger text-center">
					<p *ngFor="let error of errorMessages; let last = last" [class.mb-0]="last">{{ error }}</p>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" (click)="activeForm = 'cart';errorMessages = [];" class="btn btn-square-green">Vissza a korárhoz</button>
				<button type="submit" class="btn btn-square-red" [disabled]="orderForm.invalid || submitDisabled">Megrendelés elküldése</button>
			</div>
		</form>
	</ng-container>
</ng-container>
