import { Injectable, TemplateRef } from '@angular/core';
import { NgbToastConfig } from '@ng-bootstrap/ng-bootstrap';

interface ToastOptions extends Partial<NgbToastConfig> {
	classname?: 'success' | string;
	[key: string]: any;
}

@Injectable({ providedIn: 'root' })
export class ToastService {
	toasts: any[] = [];

	// show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
	// 	console.log('toast');
	// 	this.toasts.push({ textOrTpl, ...options });
	// }

	show(header: string, body: string, options: ToastOptions = {}) {
		this.toasts.push({ header, body, ...options });
	}

	remove(toast) {
		this.toasts = this.toasts.filter(t => t !== toast);
	}
}
