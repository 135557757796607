import { BehaviorSubject } from 'rxjs';
import { tap, pluck } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class UserService {
    constructor(_http) {
        this._http = _http;
        this.user$ = new BehaviorSubject(null);
        this.passwordResetToken$ = new BehaviorSubject(null);
        this.getLoggedInUser().subscribe();
    }
    getLoggedInUser() {
        return this._http.get('/legacy-api/api-js/json/getLoggedInUser').pipe(pluck('result', 'user'), tap(user => {
            console.log('getLoggedInUser tap', user);
            if (user)
                this.user$.next(user);
        }));
    }
    login(data) {
        return this._http.post('/legacy-api/api-js/json/login', Object.assign({ auth_type: 'password' }, data)).pipe(pluck('result', 'user'), tap(user => {
            console.log('login tap', user);
            if (user)
                this.user$.next(user);
        }));
    }
    logout() {
        this._http.post('/legacy-api/api-js/json/logout', {}).subscribe(() => {
            this.user$.next(null);
        });
    }
    register(data) {
        return this._http.post('/legacy-api/api-js/json/register', Object.assign({ auth_type: 'password' }, data)).pipe(pluck('result', 'user'), tap(user => {
            console.log('register tap', user);
        }));
    }
    createGuestUser() {
        return this._http.post('/legacy-api/api-js/json/register', {
            auth_type: 'guest'
        }).pipe(pluck('result', 'user'), tap(user => {
            console.log('createGuestUser tap', user);
            if (user)
                this.user$.next(user);
        }));
    }
    resetPassword(data) {
        return this._http.post('/legacy-api/api-js/json/resetPassword', data).pipe(pluck('result', 'user'), tap(user => {
            console.log('register tap', user);
        }));
    }
    sendPasswordResetToken(data) {
        return this._http.post('/legacy-api/api-js/json/sendPasswordResetToken', data).pipe(pluck('result', 'user'), tap(user => {
            console.log('register tap', user);
        }));
    }
    getUserByPasswordResetToken(token) {
        return this._http.post('/legacy-api/api-js/json/getUserByPasswordResetToken', {
            token,
        }).pipe(pluck('result', 'user'), tap(user => {
            console.log('register tap', user);
        }));
    }
}
UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.HttpClient)); }, token: UserService, providedIn: "root" });
