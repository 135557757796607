<ng-container [ngSwitch]="activeForm">
	<ng-container *ngSwitchCase="'no-session'">
		<div class="modal-header">
			<h2 class="modal-title">Nem vagy bejelentkezve</h2>
			<button type="button" class="btn btn-link" (click)="activeModal.dismiss()">
				<fa-icon [icon]="['far', 'times']" size="lg" [styles]="{ 'color': 'white' }"></fa-icon>
			</button>
		</div>
		<div class="modal-body">
			<p>Ha szeretnéd az elkészített címkét tartósan elmenteni, be kell jelentkezned. Ha még nincs felhasználód, most regisztrálhatsz.</p>
			<p>Vendégként is folytathatod, ebben az esetben a mentés csak arra az időtartamra vonatkozik, amíg el nem hagyod az oldalt. Természetesen az így mentett címkék is hozzáadhatóak a kosárhoz, és megrendelhetőek. (Ha közben mégis bejelentkeznél, a vendégként létrehozott címkék és kosár elvesznek!)</p>
		</div>
		<div *ngIf="errorMessages.length" class="alert alert-danger text-center">
			<p *ngFor="let error of errorMessages; let last = last" [class.mb-0]="last">{{ error }}</p>
		</div>
		<div class="modal-footer">
			<button type="button" (click)="activeForm = 'login'" class="btn btn-square-green">Belépés</button>
			<button type="button" (click)="activeForm = 'registration'" class="btn btn-square-green">Regisztráció</button>
			<button type="button" (click)="continueAsGuest()" [disabled]="submitDisabled" class="btn btn-square-green">Folytatás vendégként</button>
		</div>
	</ng-container>
	<ng-container *ngSwitchCase="'login'">
		<div class="modal-header">
			<h2 class="modal-title">Belépés</h2>
			<button type="button" class="btn btn-link" (click)="activeModal.dismiss()">
				<fa-icon [icon]="['far', 'times']" size="lg" [styles]="{ 'color': 'white' }"></fa-icon>
			</button>
		</div>
		<form [formGroup]="loginForm" (ngSubmit)="submitLogin()" id="form-login" novalidate>
			<div class="modal-body">
				<div class="row">
					<div class="col-md-6">
						<div class="form-group">
							<label for="login_email">E-mail cím</label>
							<input id="login_email" type="email" name="login_email" formControlName="email" class="form-control" required autocomplete="username">
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group">
							<label for="login_password">Jelszó</label>
							<input id="login_password" type="password" name="login_password" formControlName="password" class="form-control" required autocomplete="current-password">
						</div>
					</div>
				</div>
				<p class="text-right">
					<a nohref (click)="activeForm = 'password-reset-token-request'; errorMessages = [];">Elfelejtetted a jelszavad?</a>
				</p>
				<div *ngIf="errorMessages.length" class="alert alert-danger text-center">
					<p *ngFor="let error of errorMessages; let last = last" [class.mb-0]="last">{{ error }}</p>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" (click)="activeForm = 'registration';errorMessages = [];" class="btn btn-square-green">Regisztráció</button>
				<button type="button" (click)="continueAsGuest()" [disabled]="submitDisabled" class="btn btn-square-green">Folytatás vendégként</button>
				<button type="submit" [disabled]="loginForm.invalid || submitDisabled" class="btn btn-square-red">Belépés</button>
			</div>
		</form>
	</ng-container>
	<ng-container *ngSwitchCase="'password-reset-token-request'">
		<div class="modal-header">
			<h2 class="modal-title">Elfelejtett jelszó</h2>
			<button type="button" class="btn btn-link" (click)="activeModal.dismiss()">
				<fa-icon [icon]="['far', 'times']" size="lg" [styles]="{ 'color': 'white' }"></fa-icon>
			</button>
		</div>
		<form [formGroup]="passwordResetTokenRequestForm" (ngSubmit)="submitPasswordResetTokenRequest()" id="form-password_reset_token_request" novalidate>
			<div class="modal-body">
				<div class="form-group">
					<label for="password_reset_token_request_email">E-mail cím</label>
					<input id="password_reset_token_request_email" type="email" formControlName="email" class="form-control" required autocomplete="username">
				</div>
				<div *ngIf="errorMessages.length" class="alert alert-danger text-center">
					<p *ngFor="let error of errorMessages; let last = last" [class.mb-0]="last">{{ error }}</p>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" (click)="activeForm = 'login';errorMessages = [];" class="btn btn-square-green">Vissza a belépéshez</button>
				<button type="submit" [disabled]="passwordResetTokenRequestForm.invalid || submitDisabled" class="btn btn-square-red">Küldés</button>
			</div>
		</form>
	</ng-container>
	<ng-container *ngSwitchCase="'password-reset'">
		<div class="modal-header">
			<h2 class="modal-title">Új jelszó</h2>
			<button type="button" class="btn btn-link" (click)="activeModal.dismiss()">
				<fa-icon [icon]="['far', 'times']" size="lg" [styles]="{ 'color': 'white' }"></fa-icon>
			</button>
		</div>
		<form [formGroup]="passwordResetForm" (ngSubmit)="submitPasswordReset()" id="form-password_reset" novalidate>
			<div class="modal-body">
				<div class="row">
					<div class="col-md-6">
						<div class="form-group">
							<label for="registration_password">Jelszó</label>
							<input id="registration_password" type="password" name="password" formControlName="password" class="form-control" required autocomplete="new-password">
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group">
							<label for="registration_password_confirm">Jelszó újra</label>
							<input id="registration_password_confirm" type="password" name="password_confirm" formControlName="password_confirm" class="form-control" required autocomplete="new-password">
						</div>
					</div>
				</div>
				<div *ngIf="errorMessages.length" class="alert alert-danger text-center">
					<p *ngFor="let error of errorMessages; let last = last" [class.mb-0]="last">{{ error }}</p>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" (click)="activeForm = 'login';errorMessages = [];" class="btn btn-square-green">Vissza a belépéshez</button>
				<button type="submit" [disabled]="passwordResetForm.invalid || submitDisabled" class="btn btn-square-red">Küldés</button>
			</div>
		</form>
		<!-- {{ passwordResetForm.value | json }} -->
	</ng-container>
	<ng-container *ngSwitchCase="'registration'">
		<div class="modal-header">
			<h2 class="modal-title">Regisztráció</h2>
			<button type="button" class="btn btn-link" (click)="activeModal.dismiss()">
				<fa-icon [icon]="['far', 'times']" size="lg" [styles]="{ 'color': 'white' }"></fa-icon>
			</button>
		</div>
		<form [formGroup]="registrationForm" (ngSubmit)="submitRegistration()" id="form-registration" novalidate>
			<div class="modal-body">
				<p>Kérlek add meg az adataidat és regisztrálj, hogy be tudj lépni a Rossmann Design tervezőfelületére!</p>
				<div class="row">
					<div class="col-md-6">
						<div class="form-group">
							<label for="registration_lastname">Vezetéknév</label>
							<input id="registration_lastname" type="text" name="lastname" formControlName="lastname" class="form-control" required>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group">
							<label for="registration_firstname">Keresztnév</label>
							<input id="registration_firstname" type="text" name="firstname" formControlName="firstname" class="form-control" required>
						</div>
					</div>
				</div>
				<div class="form-group">
					<label for="registration_email">Email</label>
					<input id="registration_email" type="email" name="email" formControlName="email" class="form-control" required>
				</div>
				<div class="row">
					<div class="col-md-6">
						<div class="form-group">
							<label for="registration_password">Jelszó</label>
							<input id="registration_password" type="password" name="password" formControlName="password" class="form-control" required autocomplete="new-password">
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group">
							<label for="registration_password_confirm">Jelszó újra</label>
							<input id="registration_password_confirm" type="password" name="password_confirm" formControlName="password_confirm" class="form-control" required autocomplete="new-password">
						</div>
					</div>
				</div>
				<div class="custom-control custom-checkbox">
					<input type="checkbox" formControlName="accept_terms" id="accept_terms" class="custom-control-input" name="accept_terms" required>
					<label class="custom-control-label" for="accept_terms">Elfogadom a <a href="/assets/pdf/szabalyzat_aszf_jogi_es_adatvedelmi_nyilatkozat.pdf" target="_blank">Szabályzat, ÁSZF, Jogi és Adatvédelmi Nyilatkozat</a> dokumentumot.</label>
				</div>
				<p>Köszönjük bizalmát, melyet felénk személyes adatai megadásával kifejez. Adatait minden esetben a fent megnevezett célra korlátozva, méltányos és tiszteletteljes módon kezeljük. Amennyiben adatkezelési módszereinkről több információt szeretne, kérjük olvassa el <a href="/assets/pdf/szabalyzat_aszf_jogi_es_adatvedelmi_nyilatkozat.pdf" target="_blank">Adatvédelmi Nyilatkozatunkat</a>.</p>
				<div *ngIf="errorMessages.length" class="alert alert-danger text-center">
					<p *ngFor="let error of errorMessages; let last = last" [class.mb-0]="last">{{ error }}</p>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" (click)="activeForm = 'login';errorMessages = [];" class="btn btn-square-green">Vissza a belépéshez</button>
				<button type="submit" [disabled]="registrationForm.invalid || submitDisabled" class="btn btn-square-red">Regisztráció</button>
			</div>
		</form>
	</ng-container>
</ng-container>
