import { Observable } from 'rxjs';
import { tap, pluck, map, shareReplay } from 'rxjs/operators';
import { Stores, LegacyApiResponse } from '../models';
import { selectAll } from '../stores/selectors/cart.selectors';
import { load, add, remove } from '../stores/actions/cart.actions';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./user.service";
import * as i3 from "@ngrx/store";
export class CartService {
    constructor(_http, _user, _store) {
        this._http = _http;
        this._user = _user;
        this._store = _store;
        // public readonly action$ = new ReplaySubject<Action>();
        // public readonly cart$: Observable<Cart> = of([]).pipe(
        // 	switchMapTo(this.action$),
        // 	tap(action => console.log('cart action', action)),
        // 	concatMap(action => {
        // 		switch (action.type) {
        // 			case 'load': return this.getCart();
        // 			case 'add': return this.addCartItem(action.design_id);
        // 			case 'remove': return this.removeCartItem(action.design_id, action.amount || 1);
        // 		}
        // 	}),
        // 	shareReplay()
        // );
        this.cart$ = this._store.select(selectAll).pipe(tap(x => console.log('cart')), shareReplay());
        this.cartItemCount$ = this.cart$.pipe(map(cart => cart.reduce((count, item) => count += item.amount, 0)), tap(count => console.log('cart item count', count)));
        this.cartPriceTotal$ = this.cart$.pipe(map(cart => cart.reduce((price, item) => price += (item.amount * item.design.product_price), 0)), tap(total => console.log('cart price total', total)));
        this._user.user$.subscribe(user => {
            console.log('cart service subscription to user', user);
            if (user) {
                this._store.dispatch(load());
            }
        });
    }
    addOne(design_id) {
        this._store.dispatch(add({ design_id }));
    }
    removeOne(design_id) {
        this.removeMany(design_id);
    }
    removeMany(design_id, amount = 1) {
        this._store.dispatch(remove({ design_id, amount }));
    }
    orderCart(data) {
        return this._http.post('/legacy-api/api-js/json/orderCart', Object.assign({}, data)).pipe(pluck('result'), tap(result => {
            console.log('orderCart tap', result);
            this._store.dispatch(load());
        }));
    }
    getStores() {
        return this._http.get('/legacy-api/api-js/json/getStores').pipe(pluck('result'), tap(stores => {
            console.log('getStores tap', stores);
        }));
    }
}
CartService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CartService_Factory() { return new CartService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.UserService), i0.ɵɵinject(i3.Store)); }, token: CartService, providedIn: "root" });
