import { createReducer, on, Action } from '@ngrx/store';
import { State, initialState, adapter } from '../states/cart.state';
import { loadSuccess, updateSuccess, addSuccess } from '../actions/cart.actions';

// reducers

const cartReducer = createReducer(
	initialState,
	on(loadSuccess, (state, { cart }) => {
		return adapter.addAll(cart, state);
	}),
	on(updateSuccess, (state, { cartItemUpdate }) => {
		if (cartItemUpdate.changes.amount > 0) {
			return adapter.updateOne(cartItemUpdate, state);
		} else {
			return adapter.removeOne(cartItemUpdate.id.toString(), state);
		}
	}),
	on(addSuccess, (state, { cartItem }) => {
		return adapter.upsertOne(cartItem, state);
	}),
);

export function reducer(state: State | undefined, action: Action) {
	return cartReducer(state, action);
}


