import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';

@Component({
	selector: 'app',
	templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit {
	constructor(
		angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
		@Inject(PLATFORM_ID) private platformId: object,
	) {
		angulartics2GoogleTagManager.startTracking();
	}

	private _addPassiveTouchStartListenerToBody() {
		// Test via a getter in the options object to see if the passive property is accessed
		let supportsPassive = false;
		try {
			const opts = Object.defineProperty({}, 'passive', {
				get: () => {
					supportsPassive = true;
				}
			});
			window.addEventListener('testPassive', null, opts);
			window.removeEventListener('testPassive', null, opts);
		} catch (e) {
			//
		}
		// Use our detect's results. passive applied if supported, capture will be false either way.
		document.body.addEventListener('touchstart', () => void 0, supportsPassive ? { passive: true } : false);
	}

	ngOnInit() {
		if (isPlatformBrowser(this.platformId)) {
			this._addPassiveTouchStartListenerToBody();
		}
	}
}
