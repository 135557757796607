import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PlatformLocation } from '@angular/common';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorService } from '@app/core/services/error';
import { UserService } from '@app/shared/services/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastService } from '@app/shared/services/toast.service';


@Component({
	selector: 'rossmann-modal-login-registration',
	templateUrl: 'login-registration.component.html',
	// styleUrls: [ 'login-registration.component.scss' ],
})
export class LoginRegistrationModalComponent {

	public readonly loginForm = new FormGroup({
		email: new FormControl('', [
			Validators.required,
			Validators.email,
		]),
		password: new FormControl('', [
			Validators.required,
		]),
	});

	public readonly passwordResetTokenRequestForm = new FormGroup({
		email: new FormControl('', [
			Validators.required,
			Validators.email,
		]),
	});

	public readonly passwordResetForm = new FormGroup({
		token: new FormControl(),
		password: new FormControl('', [
			Validators.required,
		]),
		password_confirm: new FormControl('', [
			Validators.required,
		]),
	});

	public readonly registrationForm = new FormGroup({
		lastname: new FormControl('', [
			Validators.required,
		]),
		firstname: new FormControl('', [
			Validators.required,
		]),
		email: new FormControl('', [
			Validators.required,
			Validators.email,
		]),
		password: new FormControl('', [
			Validators.required,
		]),
		password_confirm: new FormControl('', [
			Validators.required,
		]),
		accept_terms: new FormControl('', [
			Validators.requiredTrue,
		]),
	});

	public activeForm = 'no-session';
	public submitDisabled = false;
	public errorMessages: string[] = [];

	constructor(
		private _user: UserService,
		private _modal: NgbModal,
		private _location: PlatformLocation,
		public activeModal: NgbActiveModal,
		public errorProvider: ErrorService,
		private _toast: ToastService,
	) {
		this._location.onPopState(() => this.activeModal.dismiss());
	}

	public submitLogin() {
		this.errorMessages = [];
		this.submitDisabled = true;
		this._user.login(this.loginForm.value).subscribe(
			result => {
				console.log(result);
				this.submitDisabled = false;
				this.activeModal.close();
			},
			(error: HttpErrorResponse) => {
				console.error(error.error);
				this.errorMessages = error.error.errors.map(error => error.error_code);
				this.submitDisabled = false;
			}
		);
	}

	public submitRegistration() {
		this.errorMessages = [];
		this.submitDisabled = true;
		this._user.register(this.registrationForm.value).subscribe(
			result => {
				console.log(result);
				this.submitDisabled = false;
				this.activeForm = 'login';
			},
			(error: HttpErrorResponse) => {
				console.error(error.error);
				this.errorMessages = error.error.errors.map(error => error.error_code);
				this.submitDisabled = false;
			}
		);
	}

	public continueAsGuest() {
		this.errorMessages = [];
		this.submitDisabled = true;
		this._user.createGuestUser().subscribe(
			result => {
				console.log(result);
				this.submitDisabled = false;
				this.activeModal.close();

				this._toast.show('Folytatás vendégként', 'Az oldal elhagyása után a tervezett címkék és a kosár tartalma elvész!', { classname: 'success' });
			},
			(error: HttpErrorResponse) => {
				console.error(error.error);
				this.errorMessages = error.error.errors.map(error => error.error_code);
				this.submitDisabled = false;
			}
		);
	}

	public submitPasswordResetTokenRequest() {
		this.errorMessages = [];
		this.submitDisabled = true;
		this._user.sendPasswordResetToken(this.passwordResetTokenRequestForm.value).subscribe(
			result => {
				console.log(result);
				this.submitDisabled = false;
				this.activeModal.dismiss();

				this._toast.show('Elfelejtett jelszó', 'Amennyiben a megadott email címmel van regisztráció a rendszerünkben, a jelszó módosításához szükséges instrukciókat emailben elküldtük.', { classname: 'success' });
			},
			(error: HttpErrorResponse) => {
				console.error(error.error);
				this.errorMessages = error.error.errors.map(error => error.error_code);
				this.submitDisabled = false;
			}
		);
	}

	public submitPasswordReset() {
		this.errorMessages = [];
		this.submitDisabled = true;
		this._user.resetPassword(this.passwordResetForm.value).subscribe(
			result => {
				console.log(result);
				this.submitDisabled = false;
				this.activeForm = 'login';

				this._toast.show('Új jelszó', 'A jelszót módosítottuk.', { classname: 'success' });
			},
			(error: HttpErrorResponse) => {
				console.error(error.error);
				this.errorMessages = error.error.errors.map(error => error.error_code);
				this.submitDisabled = false;
			}
		);
	}

	// public showMessageModal({ title, message }: MessageModalParams): Promise<any> {
	// 	const modal = this.modalService.open(MessageModalComponent);
	// 	modal.componentInstance.title = title;
	// 	modal.componentInstance.message = message;
	// 	return modal.result;
	// }
}
