import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { load, loadSuccess, add, remove, updateSuccess, addSuccess } from '../actions/cart.actions';
import { concatMap, pluck, map, catchError } from 'rxjs/operators';
import { LegacyApiResponse, Cart } from '@app/shared/models';
import { EMPTY } from 'rxjs';
import { HttpClient } from '@angular/common/http';

// effects

@Injectable()
export class CartEffects {

	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(load),
			concatMap(() => this._http.get<LegacyApiResponse<Cart>>(
				'/legacy-api/api-js/json/getCart',
			).pipe(
				pluck('result'),
				map(cart => loadSuccess({ cart })),
				catchError(() => EMPTY)
			))
		)
	);

	add$ = createEffect(() =>
		this.actions$.pipe(
			ofType(add),
			concatMap(action => this._http.post<LegacyApiResponse<Cart>>(
				'/legacy-api/api-js/json/addCartItem',
				{
					design_id: action.design_id,
				}
			).pipe(
				pluck('result'),
				map((cart: Cart) => {
					// tslint:disable-next-line: triple-equals
					const cartItem = cart.find(item => item.design.id == action.design_id);
					console.warn('fix ngrx bug!');
					console.log(cartItem, cart, action.design_id);
					return addSuccess({ cartItem });
				}),
				catchError(() => EMPTY)
			))

		)
	);

	remove$ = createEffect(() =>
		this.actions$.pipe(
			ofType(remove),
			concatMap(action => this._http.post<LegacyApiResponse<Cart>>(
				'/legacy-api/api-js/json/removeCartItem',
				{
					design_id: action.design_id,
					amount: action.amount || 1,
				}
			).pipe(
				pluck('result'),
				mapToUpdateAction(action.design_id),
				catchError(() => EMPTY)
			))

		)
	);

	constructor(
		private actions$: Actions,
		private _http: HttpClient,
	) {}
}

const mapToUpdateAction = (design_id: number) => map((cart: Cart) => {
	// tslint:disable-next-line: triple-equals
	const updatedItem = cart.find(item => item.design.id == design_id);
	const cartItemUpdate = {
		id: design_id,
		changes: {
			amount: updatedItem ? updatedItem.amount : 0,
		}
	};
	return updateSuccess({ cartItemUpdate });
});
