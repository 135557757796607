import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
// import { SubscriptionLike } from 'rxjs';

export type MessageModalButtonType = 'close' | 'dismiss' | 'eventemitter';

export type MessageModalButton = {
	caption: string,
	type: MessageModalButtonType,
	id?: string,
	class?: string,
};

export type MessageModalParams = {
	title: string,
	message?: string,
	type?: string,
	buttons?: MessageModalButton[],
	dismissBtn?: boolean,
	options?: NgbModalOptions,
};

@Component({
	selector:		'rossmann-modal-message',
	templateUrl:	'message.component.html',
	styleUrls:		['message.component.scss'],
})
export class MessageModalComponent implements OnInit, OnDestroy {

	@Input() public title: string;
	@Input() public message: string;
	@Input() public type?: string;
	@Input() public buttons?: MessageModalButton[];
	@Input() public dismissBtn = false;

	@Output() public buttonClick = new EventEmitter<string>();

	// private _locationSubscription: SubscriptionLike;

	constructor(
		public activeModal: NgbActiveModal,
		private _location: PlatformLocation,
	) {
	}

	public ngOnInit() {
		this._location.onPopState(() => this.activeModal.dismiss());
		// this._locationSubscription = this.location.subscribe(x => {
		// 	console.log(x);
		// 	this.activeModal.close();
		// });
	}

	public ngOnDestroy(): void {
		// this._locationSubscription.unsubscribe();
	}

	public buttonClicked(id: string) {
		console.log('mesage modal btn clicked', id);
		this.buttonClick.emit(id);
	}
}
