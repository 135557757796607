<div class="details">
	<div class="details__img">
		<header>Előnézet</header>
		<img [src]="cartItem.design.url_image" class="img-fluid" alt="">
	</div>
	<div class="details__name">
		<header>Termék neve</header>
		<p><strong>{{ cartItem.design.product_name }}</strong><br> {{ cartItem.design.product_type }}</p>
	</div>
</div>
<div class="functions">
	<div class="functions__count">
		<header>DB</header>
		<p>
			{{ cartItem.amount }} db
			<button type="button" (click)="addOne()" class="btn btn-square-green btn-sm">
				<fa-icon [icon]="['far', 'angle-up']" [styles]="{ 'color': 'white' }"></fa-icon>
			</button>
			<button type="button" (click)="removeOne()" class="btn btn-square-green btn-sm">
				<fa-icon [icon]="['far', 'angle-up']" [styles]="{ 'color': 'white' }" rotate="180"></fa-icon>
			</button>
		</p>
	</div>
	<div class="functions__prize">
		<header>Ár</header>
		<p>{{ cartItem.design.product_price }} Ft</p>
	</div>
	<div class="functions__delete">
		<header>Törlés</header>
		<p>
			Törlés
			<button type="button" (click)="removeAll()" class="btn btn-link btn-sm">
				<fa-icon [icon]="['far', 'times']" [styles]="{ 'color': '#688ba0' }"></fa-icon>
			</button>
		</p>
	</div>
</div>
