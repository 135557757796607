import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, ReplaySubject } from 'rxjs';
import { tap, pluck, map, concatMap, switchMapTo, shareReplay } from 'rxjs/operators';
import { UserService } from './user.service';
import { Cart, Stores, LegacyApiResponse } from '../models';
import { Store } from '@ngrx/store';
import { selectAll } from '../stores/selectors/cart.selectors';
import { State } from '../stores/states';
import { load, add, remove } from '../stores/actions/cart.actions';

type ActionLoad = {
	type: 'load',
};
type ActionAdd = {
	type: 'add',
	design_id: number,
};
type ActionRemove = {
	type: 'remove',
	design_id: number,
	amount?: number,
};
type Action = ActionLoad | ActionAdd | ActionRemove;


@Injectable({
	providedIn: 'root',
})
export class CartService {
	// public readonly action$ = new ReplaySubject<Action>();
	// public readonly cart$: Observable<Cart> = of([]).pipe(
	// 	switchMapTo(this.action$),
	// 	tap(action => console.log('cart action', action)),
	// 	concatMap(action => {
	// 		switch (action.type) {
	// 			case 'load': return this.getCart();
	// 			case 'add': return this.addCartItem(action.design_id);
	// 			case 'remove': return this.removeCartItem(action.design_id, action.amount || 1);
	// 		}
	// 	}),
	// 	shareReplay()
	// );

	public readonly cart$ = this._store.select(selectAll).pipe(
		tap(x => console.log('cart')),
		shareReplay()
	);

	public readonly cartItemCount$ = this.cart$.pipe(
		map(cart => cart.reduce((count, item) => count += item.amount, 0)),
		tap(count => console.log('cart item count', count)),
	);
	public readonly cartPriceTotal$ = this.cart$.pipe(
		map(cart => cart.reduce((price, item) => price += (item.amount * item.design.product_price), 0)),
		tap(total => console.log('cart price total', total)),
	);

	constructor(
		private _http: HttpClient,
		private _user: UserService,
		private _store: Store<State>,
	) {
		this._user.user$.subscribe(user => {
			console.log('cart service subscription to user', user);
			if (user) {
				this._store.dispatch(load());
			}
		});
	}

	public addOne(design_id: number) {
		this._store.dispatch(add({ design_id }));
	}

	public removeOne(design_id: number) {
		this.removeMany(design_id);
	}

	public removeMany(design_id: number, amount: number = 1) {
		this._store.dispatch(remove({ design_id, amount }));
	}

	public orderCart(data: object): Observable<any> {
		return this._http.post(
			'/legacy-api/api-js/json/orderCart',
			{
				...data,
			}
		).pipe(
			pluck('result'),
			tap(result => {
				console.log('orderCart tap', result);
				this._store.dispatch(load());
			}),
		);
	}

	public getStores(): Observable<Stores> {
		return this._http.get<LegacyApiResponse<Stores>>(
			'/legacy-api/api-js/json/getStores',
		).pipe(
			pluck('result'),
			tap(stores => {
				console.log('getStores tap', stores);
			})
		);
	}

}
