/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./shared/components/header/header.component.ngfactory";
import * as i2 from "./shared/components/header/header.component";
import * as i3 from "@angular/router";
import * as i4 from "./shared/services/toast.service";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "./shared/services/user.service";
import * as i7 from "./shared/services/cart.service";
import * as i8 from "./shared/components/footer/footer.component.ngfactory";
import * as i9 from "./shared/components/footer/footer.component";
import * as i10 from "./shared/components/toasts/toasts.component.ngfactory";
import * as i11 from "./shared/components/toasts/toasts.component";
import * as i12 from "./app.component";
import * as i13 from "angulartics2/gtm";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "header", [["top", ""]], null, null, null, i1.View_HeaderComponent_0, i1.RenderType_HeaderComponent)), i0.ɵdid(1, 49152, null, 0, i2.HeaderComponent, [i3.Router, i4.ToastService, i5.NgbModal, i6.UserService, i7.CartService], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 2, "main", [], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(4, 212992, null, 0, i3.RouterOutlet, [i3.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵeld(5, 0, null, null, 1, "footer", [], null, null, null, i8.View_FooterComponent_0, i8.RenderType_FooterComponent)), i0.ɵdid(6, 49152, null, 0, i9.FooterComponent, [], null, null), (_l()(), i0.ɵeld(7, 0, null, null, 1, "app-toasts", [["aria-atomic", "true"], ["aria-live", "polite"]], [[2, "ngb-toasts", null]], null, null, i10.View_ToastsContainerComponent_0, i10.RenderType_ToastsContainerComponent)), i0.ɵdid(8, 49152, null, 0, i11.ToastsContainerComponent, [i4.ToastService], null, null)], function (_ck, _v) { _ck(_v, 4, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 8).class; _ck(_v, 7, 0, currVal_0); }); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 114688, null, 0, i12.AppComponent, [i13.Angulartics2GoogleTagManager, i0.PLATFORM_ID], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i0.ɵccf("app", i12.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
