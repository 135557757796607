import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModalModule, NgbCollapseModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faTimes, faShoppingCart, faSignOutAlt, faAngleUp, faSearch, faPaintBrushAlt, faCartPlus, faHeart, faCloudDownloadAlt, faRedo, faReplyAll, faTrashAlt, faBringForward, faSendBackward, faUpload, faImage, faSync } from '@fortawesome/pro-regular-svg-icons';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';

import { SHARED_COMPONENTS, SHARED_ENTRY_COMPONENTS } from './components';
import { SHARED_MODAL_COMPONENTS } from './modals';
import { SHARED_DIRECTIVES } from './directives';
import { SHARED_PIPES } from './pipes';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { featureKey, reducers, effects } from './stores';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		ReactiveFormsModule,
		FontAwesomeModule,

		NgSelectModule,
		NgbModalModule,
		NgbCollapseModule,
		NgbToastModule,

		StoreModule.forFeature(featureKey, reducers),
		EffectsModule.forFeature(effects),

	],
	declarations: [
		...SHARED_COMPONENTS,
		...SHARED_MODAL_COMPONENTS,
		...SHARED_DIRECTIVES,
		...SHARED_PIPES
	],
	exports: [
		...SHARED_COMPONENTS,
		...SHARED_MODAL_COMPONENTS,
		...SHARED_DIRECTIVES,
		...SHARED_PIPES,

		NgSelectModule,
		NgbCollapseModule,
		NgbToastModule,
		FontAwesomeModule,
	],
	entryComponents: [
		...SHARED_ENTRY_COMPONENTS,
		...SHARED_MODAL_COMPONENTS,
	],
})
export class SharedModule {
	constructor(library: FaIconLibrary) {
		library.addIcons(faTimes, faShoppingCart, faSignOutAlt, faAngleUp, faSearch, faPaintBrushAlt, faCartPlus, faHeart, faFacebookF, faCloudDownloadAlt, faRedo, faReplyAll, faTrashAlt, faBringForward, faSendBackward, faUpload, faImage, faSync);
	}
}
