import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PlatformLocation } from '@angular/common';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorService } from '@app/core/services/error';
import { CartService } from '@app/shared/services/cart.service';
import { map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastService } from '@app/shared/services/toast.service';


@Component({
	selector: 'rossmann-modal-cart',
	templateUrl: 'cart.component.html',
	styleUrls: [ 'cart.component.scss' ],
})
export class CartModalComponent {

	public readonly cart$ = this._cart.cart$;
	public readonly cartPriceTotal$ = this._cart.cartPriceTotal$;

	public readonly stores$ = this._cart.getStores().pipe(
		map(stores => stores.map(store => ({ value: store.id, label: `${store.name}, ${store.address}`}))),
	);

	public readonly orderForm = new FormGroup({
		lastname: new FormControl('', [
			Validators.required,
		]),
		firstname: new FormControl('', [
			Validators.required,
		]),
		email: new FormControl('', [
			Validators.required,
			Validators.email,
		]),
		postal_code: new FormControl('', [
			Validators.required,
		]),
		city: new FormControl('', [
			Validators.required,
		]),
		street_address: new FormControl('', [
			Validators.required,
		]),
		phone_area: new FormControl('', [
			Validators.required,
		]),
		phone_subscriber: new FormControl('', [
			Validators.required,
		]),
		store_id: new FormControl('', [
			Validators.required,
		]),
		// accept_terms: new FormControl('', [
		// 	Validators.requiredTrue,
		// ]),
	});

	public activeForm = 'cart';
	public submitDisabled = false;
	public errorMessages: string[] = [];

	constructor(
		private _cart: CartService,
		private _location: PlatformLocation,
		public activeModal: NgbActiveModal,
		public errorProvider: ErrorService,
		private _toast: ToastService,

		// private _store: Store<State>,
	) {
		this._location.onPopState(() => this.activeModal.dismiss());

		console.warn('TODO: error message when cart is full.');
	}

	public submitOrder() {
		this.submitDisabled = true;
		this._cart.orderCart(this.orderForm.value).subscribe(
			result => {
				console.log(result);
				this.submitDisabled = false;
				this.activeModal.dismiss();

				this._toast.show('Rendelésedet rögzítettük', 'Rendelésed állapotárol hamarosan értesítünk.', { classname: 'success' });
			},
			(error: HttpErrorResponse) => {
				console.error(error.error);
				this.errorMessages = error.error.errors.map(error => error.error_code);
				this.submitDisabled = false;
			}
		);
	}
}
