import { createSelector, createFeatureSelector } from '@ngrx/store';
import { State, subFeatureKey, adapter } from '../states/cart.state';
import { featureKey } from '../states';

// selectors

const getFeatureState = createFeatureSelector<State>(featureKey);

const getCartState = createSelector(
	getFeatureState,
	(state: State) => state[subFeatureKey]
);


export const {
	// selectEntities,
	selectAll,
} = adapter.getSelectors(getCartState);


