/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./toasts.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "@angular/common";
import * as i5 from "./toasts.component";
import * as i6 from "../../services/toast.service";
var styles_ToastsContainerComponent = [i0.styles];
var RenderType_ToastsContainerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ToastsContainerComponent, data: {} });
export { RenderType_ToastsContainerComponent as RenderType_ToastsContainerComponent };
function View_ToastsContainerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ngb-toast", [["aria-atomic", "true"], ["role", "alert"]], [[8, "className", 0], [1, "aria-live", 0], [2, "toast", null], [2, "show", null]], [[null, "hide"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("hide" === en)) {
        var pd_0 = (_co.toastService.remove(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_NgbToast_0, i2.RenderType_NgbToast)), i1.ɵdid(1, 1622016, null, 1, i3.NgbToast, [[8, null], i3.NgbToastConfig], { delay: [0, "delay"], autohide: [1, "autohide"], header: [2, "header"] }, { hideOutput: "hide" }), i1.ɵqud(335544320, 1, { contentHeaderTpl: 0 }), (_l()(), i1.ɵted(3, 0, [" ", "\n"]))], function (_ck, _v) { var currVal_4 = (_v.context.$implicit.delay || 5000); var currVal_5 = true; var currVal_6 = _v.context.$implicit.header; _ck(_v, 1, 0, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.classname; var currVal_1 = i1.ɵnov(_v, 1).ariaLive; var currVal_2 = true; var currVal_3 = true; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_7 = _v.context.$implicit.body; _ck(_v, 3, 0, currVal_7); }); }
export function View_ToastsContainerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToastsContainerComponent_1)), i1.ɵdid(1, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.toastService.toasts; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ToastsContainerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-toasts", [], [[2, "ngb-toasts", null]], null, null, View_ToastsContainerComponent_0, RenderType_ToastsContainerComponent)), i1.ɵdid(1, 49152, null, 0, i5.ToastsContainerComponent, [i6.ToastService], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).class; _ck(_v, 0, 0, currVal_0); }); }
var ToastsContainerComponentNgFactory = i1.ɵccf("app-toasts", i5.ToastsContainerComponent, View_ToastsContainerComponent_Host_0, {}, {}, []);
export { ToastsContainerComponentNgFactory as ToastsContainerComponentNgFactory };
